import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import isEmpty from 'lodash/isEmpty';

import SEO from 'src/components/SEO';

import { CardGridWrapper, FloatingTryForFree, WorkoutCard } from 'src/components/shared';
import Layout from 'src/components/layout/Layout';
import Selectors from 'src/state/root-selectors';
import Actions from 'src/state/root-actions';
import { IWorkout } from '@pvolve/sdk/src/models/contentful_types';
import Loader from 'src/components/shared/Loader';

import * as Styles from 'src/styles/new-workouts.module.scss';
import Colors from 'src/utils/colors';

const NewWorkout = ({ location }) => {
    const entitled = useSelector(Selectors.auth.entitled);
    const targetedNewWorkouts: IWorkout[] = useSelector(
        Selectors.workouts.getTargetedNewWorkoutsSortedByDate
    );
    const userCohort = useSelector(Selectors.account.userCohort);

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!isEmpty(targetedNewWorkouts)) {
            setLoading(false);
        }
    }, [targetedNewWorkouts]);

    useEffect(() => {
        dispatch(Actions.workouts.loadTargetedNewWorkouts.trigger());
    }, [userCohort, dispatch]);

    const hasWorkouts = targetedNewWorkouts?.length > 0;
    const pageTitle = hasWorkouts ? 'New Workouts' : 'No workouts found';
    const workoutsCount = targetedNewWorkouts?.length || 0;

    const pageContext = {};

    return (
        <Layout pageContext={pageContext} location={location}>
            {loading ? (
                <Loader
                    spinnerColor={Colors.black}
                    className="margin-top--large margin-bottom--large"
                />
            ) : (
                <div className={Styles.wrapper}>
                    <SEO title="New Workouts" />
                    <Grid>
                        <Grid.Row className="page-section">
                            <Grid.Column>
                                <Grid container>
                                    <Grid.Row verticalAlign="bottom" className="padding--0">
                                        <Grid.Column width={10} floated="left">
                                            <h2 className="upper bold">{pageTitle}</h2>
                                        </Grid.Column>
                                        {hasWorkouts && (
                                            <Grid.Column
                                                width={6}
                                                floated="right"
                                                textAlign="right"
                                            >
                                                <span>
                                                    {workoutsCount} Workout
                                                    {workoutsCount > 1 ? 's' : ''}
                                                </span>
                                            </Grid.Column>
                                        )}
                                    </Grid.Row>

                                    {hasWorkouts && (
                                        <CardGridWrapper>
                                            {targetedNewWorkouts.map(
                                                (workout: IWorkout, index: number) => {
                                                    const newWorkout = {
                                                        ...workout,
                                                        new: true,
                                                    };

                                                    return (
                                                        <WorkoutCard
                                                            key={`workout-card-${index}`}
                                                            workout={newWorkout}
                                                        />
                                                    );
                                                }
                                            )}
                                        </CardGridWrapper>
                                    )}
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )}
            {!entitled && <FloatingTryForFree />}
        </Layout>
    );
};

export default NewWorkout;
